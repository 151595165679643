<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="盘点单ID" >
                <el-input v-model="search.panDianDanID" size="small" />
            </x-search-item>
            <x-search-item label="盘点仓库ID" >
                <el-input v-model="search.panDianCangKuID" size="small" />
            </x-search-item>
            <x-search-item label="盘点仓库名称" >
                <el-input v-model="search.panDianCangKuMC" size="small" />
            </x-search-item>
            <x-search-item label="操作人" >
                <el-input v-model="search.caoZuoRen" size="small" />
            </x-search-item>
            <x-search-item label="盘点状态" >
                <el-input v-model="search.panDianZT" size="small" />
            </x-search-item>
<!--            <x-search-item label="主要事项" >-->
<!--                <el-input v-model="search.title" size="small" />-->
<!--            </x-search-item>-->
            <x-search-item label="审核状态" >
                <x-selector-one v-model="search.auditStatus" size="small" dictType="T_AUDIT_STATUS"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="panDianDanID" label="盘点单ID" />
            <el-table-column width="180" prop="panDianCangKuID" label="盘点仓库ID" />
            <el-table-column width="180" prop="panDianCangKuMC" label="盘点仓库名称" />
            <el-table-column width="180" prop="caoZuoRenID" label="操作人ID" />
            <el-table-column width="180" prop="caoZuoRen" label="操作人" />
            <el-table-column width="180" prop="panDianKaiShiSJ" label="盘点开始时间" />
            <el-table-column width="180" prop="panDianJieShuSJ" label="盘点结束时间" />
            <el-table-column width="180" prop="panDianShangPinZhongLei" label="盘点商品种类" />
            <el-table-column width="180" prop="panDianZT" label="盘点状态" >
                <x-dict-show slot-scope="{row}" :code="row.panDianZT" dictType="T_PAN_DIAN_STATUS" />
            </el-table-column>
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" prop="title" label="主要事项" />
            <el-table-column width="180" label="审核状态">
                <x-dict-show slot-scope="{row}" :code="row.auditStatus" dictType="T_AUDIT_STATUS" />
            </el-table-column>
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import {query} from "@/service/kcgl/PanDianXX";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/kcgl/pdgl/PanDianXXDetail";
    import {DICT_AUDIT_STATUS,DICT_TYPE_PAN_DIAN_STATUS} from "@/util/constant";

    export default {
        name: "PanDianXXQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = query;
            return {
                search: {
                    panDianDanID: '',
                    panDianCangKuID: '',
                    panDianCangKuMC: '',
                    caoZuoRen: '',
                    panDianZT: '',
                    title: '',
                    auditStatus: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>